import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourcesEndpointEnum } from '../enums/resources-endpoint.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  startDateSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private readonly baseUrl = environment.resources.portalAppAPI.resourceUri;

  constructor(private http: HttpClient) { }


  get<T>(endpoint: ResourcesEndpointEnum, params?: object): Observable<T> {
    return this.http.get(this.createURI(endpoint, params)).pipe(
      map((response: object) => response as unknown as T)
    );
  }

  getCollection<T>(endpoint: ResourcesEndpointEnum, params?: object): Observable<T[]> {
    return this.http.get(this.createURI(endpoint, params), {
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 204) {
          return [];
        } else {
          return response.body;
        }
      })
    );
  }
  /**
   *
   */
   post<T>(endpoint: ResourcesEndpointEnum, payload: any, params?: object): Observable<T> {
    return this.http.post(this.createURI(endpoint, params), payload).pipe(
      map((response: object) => {
        return response as unknown as T;
      })
    );
  }
  /**
   *
   */
   put<T>(endpoint: ResourcesEndpointEnum, payload: any, params?: object): Observable<T> {
    return this.http.put(this.createURI(endpoint, params), payload).pipe(
      map((response: object) => {
        return response as unknown as T;
      })
    );
   }
   /**
    *
    */
  delete<T>(endpoint: ResourcesEndpointEnum, params?: object): Observable<T> {
    return this.http.delete(this.createURI(endpoint, params)).pipe(
      map((response: object) => {
        return response as unknown as T;
      })
    );
  }

  getConfiguration<T>(): Observable<T> {
    return this.http.get('https://stpowexcp.blob.core.windows.net/config-files/ConfigSettings.txt').pipe(
      map((response: object) => response as unknown as T)
    );
  }

  /**
   *
   */
   private createURI(uri: string, params?: object): string {
    if (!params) {
      return this.baseUrl + uri;
    }

    const urlArray = uri.split('/');

    urlArray.forEach((segment: any, index: number) => {
      if (segment.charAt(0) === ':') {
        urlArray[index] = params[segment.substr(1)];
      }
    });

    return this.baseUrl + urlArray.join('/');
  }
}