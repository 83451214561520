export const environment = {
    production: false,
    auth: {
        clientId: 'a9083915-7cd4-429c-8251-b33134295cae',
        tenantId: 'd1c24379-1a92-47d8-8fd6-a94dfdb2f5b7',
        redirectUri: 'https://customerportal.ingraphicdemo.io/home',
        postLogoutRedirectUri: 'https://customerportal.ingraphicdemo.io/login'
    },
    azureFunctionsKey: 'uo7IvbM5xz0EBpN-eMPNPK8IU7ZjOWDNQHUvXLhysgxvAzFu9P6XnA==',
    resources: {
        portalAppAPI: {
        resourceUri: 'https://capp-powex-cp-backend.happydesert-a4380974.norwayeast.azurecontainerapps.io/api',
        // resourceScopes: [ 'profile openid email User.Read' ]
        resourceScopes: [ 'api://PowationExternalPortal_WEB/access_as_user' ]
        }
    },
    blobStorageIllustrationPhotos: 'https://stpowexcp.blob.core.windows.net/illustration-photos'
};
// https://polite-river-09c8c6b03.2.azurestaticapps.net/
