
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor() { }

  setLightTheme() {
    document.documentElement.style.setProperty('--ion-color-primary', '#f3f6ff');
    document.documentElement.style.setProperty('--ion-color-primary-contrast', 'black');
    document.documentElement.style.setProperty('--ion-color-secondary-contrast', 'black');
    document.documentElement.style.setProperty('--ion-color-tertiary-contrast', 'black');
    document.documentElement.style.setProperty('--ion-color-secondary', '#e6e9ec')
    document.documentElement.style.setProperty('--ion-color-tertiary', 'white')
    document.documentElement.style.setProperty('--ion-color-quartiary', '#cdd0d2')
    document.documentElement.style.setProperty('--ion-color-success', '#045a8f')
    document.documentElement.style.setProperty('--ion-color-warning', '#f5b521')
    document.documentElement.style.setProperty('--ion-color-danger', '#c03e3e')
    document.documentElement.style.setProperty('--ion-color-medium', '#7b7d7e')
    document.documentElement.style.setProperty('--ion-color-light', '#f4f5f8')
    document.documentElement.style.setProperty('--ion-text-color', 'black')

  }

  setDarkTheme() {
    document.documentElement.style.setProperty('--ion-color-primary', '#001020');
    document.documentElement.style.setProperty('--ion-color-primary-contrast', '#ffffff');
    document.documentElement.style.setProperty('--ion-color-secondary-contrast', '#ffffff');
    document.documentElement.style.setProperty('--ion-color-tertiary-contrast', '#ffffff');
    document.documentElement.style.setProperty('--ion-color-secondary', '#001B2C')
    document.documentElement.style.setProperty('--ion-color-tertiary', '#02253b')
    document.documentElement.style.setProperty('--ion-color-quartiary', '#141b20')
    document.documentElement.style.setProperty('--ion-color-success', '#68b984')
    document.documentElement.style.setProperty('--ion-color-warning', '#f5b521')
    document.documentElement.style.setProperty('--ion-color-danger', '##c03e3e')
    document.documentElement.style.setProperty('--ion-color-medium', '#a4b1b1')
    document.documentElement.style.setProperty('--ion-color-light', '#f4f5f8')
    document.documentElement.style.setProperty('--ion-text-color', '#ffffff')
  }
}
