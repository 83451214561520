import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
      const toastrService = inject(ToastrService);
      const translateService = inject(TranslateService);
      const authService = inject(AuthService);
  return next(req).pipe(
      catchError((err) => {
        let errorMessage = "";
        if (err.status === 401) {
          errorMessage = "Unauthozied. You have provided invalid credentials. Please contact IT.";
          authService.logout()
        }
        else if(err.status === 403) {
          errorMessage = "Forbidden. You do not have access to the resource";
        }
        else if(err.status === 500) {
          console.log('err in 500: ', err)
          errorMessage = "Internal Server Error. Please contact IT.";
        }
        else if(err.status === 0){
          errorMessage = "Could not connect to server. Please contact IT.";
        }
        else if(err.status === 400){
          errorMessage = err.error;
        }
        return throwError(()  => {
          translateService.get(errorMessage).subscribe(t => {
            toastrService.error(t)
          })
        });
      })
    );
};